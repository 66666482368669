@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



@font-face {
  font-family: 'GT Super Ds Trial Bd';
  src: url('./fonts/GTSuper/GTSuperDsTrial-Bd.eot');
  src: local('./fonts/GTSuper/GT Super Ds Trial Bd'), local('./fonts/GTSuper/GTSuperDsTrial-Bd'),
      url('./fonts/GTSuper/GTSuperDsTrial-Bd.eot?#iefix') format('embedded-opentype'),
      url('./fonts/GTSuper/GTSuperDsTrial-Bd.woff2') format('woff2'),
      url('./fonts/GTSuper/GTSuperDsTrial-Bd.woff') format('woff'),
      url('./fonts/GTSuper/GTSuperDsTrial-Bd.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Super Ds Trial Su';
  src: url('./fonts/GTSuper/GTSuperDsTrial-Su.eot');
  src: local('./fonts/GTSuper/GT Super Ds Trial Su'), local('./fonts/GTSuper/GTSuperDsTrial-Su'),
      url('./fonts/GTSuper/GTSuperDsTrial-Su.eot?#iefix') format('embedded-opentype'),
      url('./fonts/GTSuper/GTSuperDsTrial-Su.woff2') format('woff2'),
      url('./fonts/GTSuper/GTSuperDsTrial-Su.woff') format('woff'),
      url('./fonts/GTSuper/GTSuperDsTrial-Su.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro Bold';
  src: url('./fonts/GTWalsheim/GTWalsheimPro-Bold.eot');
  src: local('./fonts/GTWalsheim/GT Walsheim Pro Bold'), local('./fonts/GTWalsheim/GTWalsheimPro-Bold'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Bold.eot?#iefix') format('embedded-opentype'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Bold.woff2') format('woff2'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Bold.woff') format('woff'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'GT Walsheim Pro Regular';
  src: url('./fonts/GTWalsheim/GTWalsheimPro-Regular.eot');
  src: local('./fonts/GTWalsheim/GT Walsheim Pro Regular'), local('./fonts/GTWalsheim/GTWalsheimPro-Regular'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Regular.eot?#iefix') format('embedded-opentype'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Regular.woff2') format('woff2'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Regular.woff') format('woff'),
      url('./fonts/GTWalsheim/GTWalsheimPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.title {
  line-height: 70px !important;
}

@media screen and (min-width: 768) {
  .title {
    line-height: 18px !important;
  }
}

@media screen and (max-width: 480px) {
  .title {
    line-height: 28px !important;
  }
}

.negativeLeft {
  left: -20px;
}
.negativeRight {
  right: -40px;
}
.negativeBottom {
  bottom: -40px;
}


:root {
  --gt-w-regular: "GT Walsheim Pro Regular";
  --gt-w-medium: "GT Walsheim Pro Medium";
  --gt-w-bold: "GT Walsheim Pro Bold";
  --gt-su: "GT Super Ds Trial Su";
  --gt-su-md: "GT Super Ds Medium";
  --gt-su-bd: "GT Super Ds Trial Bd";
}


.regular-font {
  font-family: "GT Walsheim Pro Regular";
}
.medium-font {
  font-family: "GT Walsheim Pro Medium";
}
.bold-font {
  font-family: "GT Walsheim Pro Bold";
}

.title-font-su {
  font-family: "GT Super Ds Trial Su";
}
.title-font-bd {
  font-family: "GT Super Ds Trial Bd";
}

.ig-style {
  background: radial-gradient(
      89.12% 77.38% at 31.35% 101.03%,
      #fdcf2c 0%,
      #ff6930 48.44%,
      #fc403c 73.44%,
      rgba(254, 59, 54, 0) 100%
    ),
    radial-gradient(
      207.29% 118.5% at 84.5% 113.5%,
      #ed3895 24.39%,
      #f80261 43.67%,
      #ed00c0 68.85%,
      #c500e9 77.68%,
      rgba(104, 24, 232, 0.95) 89.32%
    );
}


.faq__accordion-listItem {
  background: transparent;
  border-top: 1px solid #f1f1f1;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 16px 0px 16px 0;
  cursor: pointer;
  margin-bottom: 16px;
}
.faq__accordion-listItem header h4 {
  font-size: 16px;
  margin-bottom: 0;
  color: #061a38;
}
.faq__accordion--content {
  display: none;
  transition: 0.6s ease-in-out all;
}
.faq__accordion--content p {
  padding: 0;
  margin: 0;
  font-size: 16px;
  line-height: 28px;
}
.faq__accordion-listItem header span {
  transition: 0.6s ease-in-out all;
}
.faq__accordion-listItem header span svg path {
  stroke: rgba(0, 0, 0, 0.36);
}

@media screen and (max-width: 480px) {
  .how-it-works svg {
    width: 100%;
  }
}

.roundicon {
  position: absolute;
  left: 50%;
  top: auto;
  right: 0;
  bottom: -100px;
  overflow: hidden;
  width: 100%;
  max-width: 1488px;
  margin-left: -744px;
  opacity: 0.3;
}


.modal-grad{
  border-radius: 1063px;
background: linear-gradient(180deg, rgba(209, 77, 242, 0.69) 12.19%, rgba(246, 135, 242, 0.00) 84.68%);
}

.loader {
width: 28px;
height: 28px;
border: 5px solid #FFF;
border-bottom-color: transparent;
border-radius: 50%;
display: inline-block;
box-sizing: border-box;
animation: rotation 1s linear infinite;
}

@keyframes rotation {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
} 


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}